import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  ResourceContent,
  ResourceImage,
  ResourceMain,
  ResourceTag,
} from './index.style'
import { GatsbyImage } from 'gatsby-plugin-image'
import Spacer from '@components/Spacer'
import { Heading3, TextBodySmall } from '@components/TextStyles'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import Cta from '@components/Cta'

const Resource = forwardRef(({ data }, ref) => {
  return (
    <ResourceMain ref={ref}>
      {data.image && (
        <ResourceImage>
          <GatsbyImage image={data.image.gatsbyImageData} alt="" />
        </ResourceImage>
      )}

      <ResourceContent>
        {data.metadata.tags && (
          <>
            {React.Children.toArray(
              data.metadata.tags.map(tag => (
                <ResourceTag>{tag.name}</ResourceTag>
              ))
            )}

            <Spacer size={12} />
          </>
        )}

        <Heading3 secondary>{data.title}</Heading3>

        {data.description && (
          <>
            <Spacer size={24} />

            <TextBodySmall>{data.description.description}</TextBodySmall>
          </>
        )}

        <Spacer size={24} />

        <Cta
          type="externalLink"
          href={data.externalUrl || data.file.file.url}
          buildOn={false}
        >
          {data.ctaText}
        </Cta>
      </ResourceContent>
    </ResourceMain>
  )
})

Resource.propTypes = {
  data: PropTypes.object,
}

export default Resource
