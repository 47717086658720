import React from 'react'
import PropTypes from 'prop-types'
import { SelectLabel, SelectMain, SelectWrapper } from './index.style'
import { colors } from '@styles/vars/colors.style'
import Spacer from '@components/Spacer'

const Select = ({
  value,
  onChange,
  label,
  hideLabel,
  id,
  options,
  name,
  placeholder,
  borderColor = colors.dark,
  required,
}) => {
  return (
    <SelectWrapper>
      <SelectLabel htmlFor={id} hideLabel={hideLabel}>
        {label}
      </SelectLabel>

      {!hideLabel && <Spacer size={8} />}

      <SelectMain
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderColor={borderColor}
        required={required}
      >
        {React.Children.toArray(
          options.map(option => (
            <option value={option.value}>{option.label}</option>
          ))
        )}
      </SelectMain>
    </SelectWrapper>
  )
}

Select.propTypes = {
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  borderColor: PropTypes.string,
  hideLabel: PropTypes.bool,
  required: PropTypes.bool,
}

export default Select
