import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { font } from '@styles/vars/font.style'
import { TextBody } from '@styles/vars/textStyles.style'
import { easings } from '@styles/vars/easings.style'
import { clamp } from '@styles/utils/conversion.style'
import { grid } from '@styles/vars/grid.style'
import { mq } from '@styles/vars/media-queries.style'

export const ResourcesMain = styled.div`
  background-color: ${colors.light};

  .masonry {
    display: flex;
    ${clamp('margin-left', -grid.gap.min, -grid.gap.max)}
    width: auto;
  }

  .masonry_column {
    ${clamp('padding-left', grid.gap.min, grid.gap.max)}

    div + div {
      ${clamp('margin-top', grid.gap.min, grid.gap.max)}
    }
  }
`

export const ResourcesFiltersMobile = styled.div`
  ${mq.tabletP} {
    display: none;
  }
`

export const ResourcesFilters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq.tabletPMax} {
    display: none;
  }
`

export const ResourcesFilter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 3.2rem;
  overflow: hidden;
`

export const ResourcesFilterCheckbox = styled.input`
  position: absolute;
  top: 0;
  right: 100%;
`

export const ResourcesFilterDot = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2.6rem;
  height: 2.6rem;
  pointer-events: none;
  border-radius: 50%;
  border: 0.2rem solid ${colors.dark};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.15s ${easings.inOut.default};
`

export const ResourcesFilterLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${TextBody} {
    font-weight: ${props =>
      props.active ? font.primary.weight.bold : font.primary.weight.regular};
  }

  ::before {
    content: '';
    display: block;
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 1rem;
    border: 0.1rem solid ${colors.dark};
    border-radius: 50%;
  }

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0.7rem;
    transform: translateY(-50%);
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: ${colors.dark};
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.15s ${easings.inOut.default};
  }
`
