import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import {
  ResourcesFilter,
  ResourcesFilterCheckbox,
  ResourcesFilterDot,
  ResourcesFilterLabel,
  ResourcesFilters,
  ResourcesFiltersMobile,
  ResourcesMain,
} from './index.style'
import Spacer from '@components/Spacer'
import { TextBody } from '@components/TextStyles'
import Container from '@components/Container'
import Resource from '@components/Resource'
import Masonry from 'react-masonry-css'
import gsap from 'gsap'
import { useStore } from '@Store/'
import Select from '@components/Select'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'

const Resources = () => {
  const {
    allContentfulTag: { nodes: resourceTags },
    allContentfulResource: { nodes: resources },
  } = useStaticQuery(graphql`
    query {
      allContentfulTag {
        nodes {
          name
          contentful_id
        }
      }
      allContentfulResource {
        nodes {
          metadata {
            tags {
              name
              contentful_id
            }
          }
          image {
            ...Image
          }
          title
          description {
            description
          }
          ctaText
          externalUrl
          file {
            file {
              url
            }
          }
        }
      }
    }
  `)
  const tags = [
    {
      contentful_id: 'all',
      name: 'All',
    },
    ...resourceTags,
  ]
  const tagOptions = tags.map(tag => ({
    value: tag.contentful_id,
    label: tag.name,
  }))
  const [store] = useStore()
  const $resourceCards = useRef([])
  const [activeFilter, setActiveFilter] = useState(tags[0].contentful_id)
  const [filteredResources, setFilteredResources] = useState(resources)
  const masonryBreakpoints = {
    default: 3,
    1023: 2,
    640: 1,
  }

  const updateFilter = filter => {
    gsap.to($resourceCards.current, {
      yPercent: 10,
      opacity: 0,
      ease: 'power2.in',
      duration: 0.2,
      stagger: 0.025,
      onComplete: () => {
        gsap.set($resourceCards.current, {
          clearProps: 'transform, opacity',
        })
        setActiveFilter(filter)
      },
    })
  }

  useEffect(() => {
    const clearStyles = () => {
      gsap.set($resourceCards.current, {
        opacity: 1,
      })
    }

    window.addEventListener('resize', clearStyles)

    return () => {
      window.removeEventListener('resize', clearStyles)
    }
  }, [])

  useLayoutEffect(() => {
    if (store.showPageMask) return

    if (activeFilter === 'all') {
      setFilteredResources(resources)
    } else {
      const newResources = []

      resources.forEach(resource => {
        const tags = resource.metadata.tags

        if (tags.length) {
          if (tags.filter(tag => tag.contentful_id === activeFilter).length) {
            newResources.push(resource)
          }
        }
      })

      setFilteredResources(newResources)
    }

    gsap.delayedCall(0.1, () => {
      gsap.fromTo(
        $resourceCards.current,
        {
          yPercent: 10,
          opacity: 0,
        },
        {
          yPercent: 0,
          opacity: 1,
          ease: 'power2.out',
          duration: 0.2,
          stagger: 0.05,
        }
      )
    })
  }, [activeFilter, resources, store.showPageMask])

  return (
    <ResourcesMain>
      <Container>
        <Spacer size={[44, 88]} />

        <AnimateSlideIn>
          <ResourcesFiltersMobile>
            <Select
              id="mobile-filters"
              name="mobile-filters"
              value={activeFilter}
              onChange={e => updateFilter(e.currentTarget.value)}
              label={<TextBody>Show me</TextBody>}
              options={tagOptions}
              hideLabel
            />
          </ResourcesFiltersMobile>
        </AnimateSlideIn>

        <AnimateSlideIn>
          <ResourcesFilters>
            <TextBody>
              <strong>Show me:</strong>
            </TextBody>

            {React.Children.toArray(
              tags.map(tag => (
                <ResourcesFilter active={activeFilter === tag.contentful_id}>
                  <ResourcesFilterCheckbox
                    type="checkbox"
                    id={`filter-${tag.contentful_id}`}
                    value={tag.contentful_id}
                    onChange={() => updateFilter(tag.contentful_id)}
                  />

                  <ResourcesFilterDot
                    active={activeFilter === tag.contentful_id}
                  />

                  <ResourcesFilterLabel
                    active={activeFilter === tag.contentful_id}
                    htmlFor={`filter-${tag.contentful_id}`}
                  >
                    <TextBody>{tag.name}</TextBody>
                  </ResourcesFilterLabel>
                </ResourcesFilter>
              ))
            )}
          </ResourcesFilters>
        </AnimateSlideIn>

        <Spacer size={[24, 48]} />

        <Masonry
          breakpointCols={masonryBreakpoints}
          className="masonry"
          columnClassName="masonry_column"
        >
          {React.Children.toArray(
            filteredResources.map((resource, resourceIndex) => (
              <Resource
                ref={ref => ($resourceCards.current[resourceIndex] = ref)}
                data={resource}
              />
            ))
          )}
        </Masonry>

        <Spacer size={[44, 88]} />
      </Container>
    </ResourcesMain>
  )
}

export default Resources
