import { colors } from '@styles/vars/colors.style'
import { getStyles, textStylesConfig } from '@styles/vars/textStyles.style'
import { setScreenReaderOnlyStyles } from '@utils/utils'
import styled from 'styled-components'

export const SelectWrapper = styled.div`
  position: relative;
`

export const SelectLabel = styled.label`
  display: block;

  ${props => props.hideLabel && setScreenReaderOnlyStyles()}
`

export const SelectMain = styled.select`
  width: 100%;
  padding: 1.2rem 2.2rem;
  ${getStyles(textStylesConfig.body)}
  color: ${colors.dark};
  appearance: none;
  border-radius: 1rem;
  border: 0.1rem solid ${props => props.borderColor};
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.174 1.16a1 1 0 0 1 1.413-.001L8 6.56l5.413-5.401a1 1 0 0 1 1.413 0l.467.467a1 1 0 0 1 0 1.414L8.707 9.626a1 1 0 0 1-1.414 0L.707 3.04a1 1 0 0 1 0-1.414l.467-.466Z' fill='%231E1E1E'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 2.2rem center;

  ::placeholder {
    color: ${colors.dark};
  }
`
