import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { font } from '@styles/vars/font.style'
import { grid } from '@styles/vars/grid.style'
import styled from 'styled-components'

export const ResourceMain = styled.div`
  overflow: hidden;
  border-radius: 1.6rem;
  border: 0.1rem solid ${colors.cream};
  background-color: ${colors.cream};
  opacity: 0;

  & + & {
    ${clamp('margin-top', grid.gap.min, grid.gap.max)}
  }
`

export const ResourceTag = styled.p`
  display: inline-flex;
  padding: 0.8rem 1.5rem;
  font-size: 1.2rem;
  font-weight: ${font.primary.weight.bold};
  line-height: 1.1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background-color: ${colors.yellow};
  border-radius: 2.4rem;

  & + & {
    margin-left: 0.8rem;
  }
`

export const ResourceImage = styled.div`
  aspect-ratio: 404 / 227;
`

export const ResourceContent = styled.div`
  padding: 3.2rem 2.4rem 4rem;
`
