import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Hero from '@components/Hero'
import Footer from '@components/Footer'
import Resources from '@components/Resources'

const ResourcesPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription: { seoDescription },
    slug,
    hero,
  } = data.contentfulResources

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} pathname={slug} />

      <Hero data={hero} />

      <Resources />

      <Footer />
    </>
  )
}

export default ResourcesPage

export const pageQuery = graphql`
  query ResourcesPage($slug: String!) {
    contentfulResources(slug: { eq: $slug }) {
      seoTitle
      seoDescription {
        seoDescription
      }
      slug
      hero {
        ...ContentfulHero
      }
    }
  }
`
